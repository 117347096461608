/* eslint-env browser */
import React from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';
import _map from 'lodash.map';
import _get from 'lodash.get';
import _uniqueId from 'lodash.uniqueid';
import _reverse from 'lodash.reverse';
import _reduce from 'lodash.reduce';
import { graphql, StaticQuery } from 'gatsby';
import moment from 'moment-mini';
import _sortBy from 'lodash.sortby';
import { BrowserView, MobileView } from 'react-device-detect';

const InfoPage = loadable(() => import('../components/InfoPage'));
const ContentBox = loadable(() =>
  import('../components/sub-components/contentBox')
);


const ExhibitionRow = styled.tr`
  td {
    font-size: 15px;
    line-height: 24px;
    p {
      font-size: 15px;
      line-height: 24px;
      margin: unset;
    }
  }
`;

const ContentColumn = styled.td`
  vertical-align: text-top;
  padding-bottom: 20px;
`;

const DateColumn = styled.td`
  width: 230px;
  vertical-align: text-top;
  padding-bottom: 20px;
  padding-right: 30px;
`;

const Exhibitions = () => (
  <StaticQuery
    query={graphql`
      query allExhibitions {
        jmonehillart {
          listExhibitions(limit: 200) {
            items {
              active_date
              begin_date
              content
              exhibition_dates
              id
            }
          }
        }
      }
    `}
    render={data => {
      const today = moment();

      let exhibitions = _get(data, 'jmonehillart.listExhibitions.items');

      exhibitions = _map(exhibitions, exhibition => {
        exhibition.beginDateUnix = moment(
          _get(exhibition, 'begin_date'),
          'MM/DD/YYYY'
        ).unix();
        return exhibition;
      });

      exhibitions = _reverse(_sortBy(exhibitions, ['beginDateUnix']));

      const visibleExhibitions = _reduce(
        exhibitions,
        (result, exhibition) => {
          let activeDate = moment(
            _get(exhibition, 'active_date'),
            'MM/DD/YYYY'
          );

          if (!activeDate._isValid) {
            activeDate = today.clone();
          }
          const isTodayOrInPast = activeDate.isSameOrBefore(today);

          if (isTodayOrInPast) result.push(exhibition);

          return result;
        },
        []
      );

      const exhibitionsListDesktop = _map(visibleExhibitions, exhibition => {
        return (
          <ExhibitionRow key={_uniqueId()}>
            <DateColumn
              dangerouslySetInnerHTML={{
                __html: _get(exhibition, ['exhibition_dates']),
              }}
            />
            <ContentColumn
              dangerouslySetInnerHTML={{
                __html: _get(exhibition, ['content']),
              }}
            />
          </ExhibitionRow>
        );
      });

      const exhibitionsListMobile = _map(visibleExhibitions, exhibition => {
        return (
          <ExhibitionRow key={_uniqueId()}>
            <ContentColumn>
              <p
                dangerouslySetInnerHTML={{
                  __html: _get(exhibition, ['exhibition_dates']),
                }}
                style={{ fontWeight: 600 }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: _get(exhibition, ['content']),
                }}
              />
            </ContentColumn>
          </ExhibitionRow>
        );
      });

      return (
        <InfoPage
          title="Jennifer Mone Hill Artist - Exhibitions"
          description="Here is a listing of current and past exhibitions of Jennifer Mone Hill's artwork."
          keywords={[
            'Jennifer Moné Hill exhibitions',
            'Jennifer Moné Hill',
            'Jen Hill Artist',
            'Jennifer Mone Hill',
            'Jen Hill',
            'Jen Mone Hill',
            'shows',
            'art festivals',
            'art walk',
            'as seen',
            'on display',
            'Ridgefield Guild of Artists',
            'Carriage Barn Arts Center',
            'art fair',
            'SoCCA',
            'things to do',
          ]}
          imageLink="/works/Prism"
          imgSrc="Prism-by-Jennifer-Mone-Hill-800.jpg"
          imgSrc2x="Prism-by-Jennifer-Mone-Hill-1600.jpg"
          alt="Prism by Jennifer Moné Hill"
          customImageWidth={30}
        >
          <ContentBox>
            <h2>Exhibitions</h2>
            <BrowserView>
              <table>
                <tbody>{exhibitionsListDesktop}</tbody>
              </table>
            </BrowserView>
            <MobileView>
              <table>
                <tbody>{exhibitionsListMobile}</tbody>
              </table>
            </MobileView>
          </ContentBox>
        </InfoPage>
      );
    }}
  />
);

export default Exhibitions;
